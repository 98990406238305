import React, { useState, useEffect, useContext } from 'react';
import { Typography, Grid, Paper, Box, CircularProgress } from '@mui/material';
import AuthContext from '../contexts/AuthContext';
import api from '../api';
import {
  PeopleAlt as PeopleIcon,
  Business as BusinessIcon,
  Work as WorkIcon,
  VideoCall as InterviewIcon,
} from '@mui/icons-material';

function Dashboard() {
  const { token } = useContext(AuthContext);
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        // Use the api instance instead of axios directly
        const [interviews, jobs, clients, applicants] = await Promise.all([
          api.get('/interviews?itemsPerPage=1'),
          api.get('/jobs?itemsPerPage=1'),
          api.get('/clients?itemsPerPage=1'),
          api.get('/applicants?itemsPerPage=1')
        ]);

        setStats({
          totalInterviews: interviews.data.totalPages * interviews.data.interviews.length,
          totalJobs: jobs.data.totalPages * jobs.data.jobs.length,
          totalClients: clients.data.totalPages * clients.data.clients.length,
          totalApplicants: applicants.data.totalPages * applicants.data.applicants.length,
        });
      } catch (err) {
        setError('Failed to fetch dashboard statistics');
        console.error('Dashboard fetch error:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, [token]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography color="error" align="center">
        {error}
      </Typography>
    );
  }

  const StatCard = ({ title, value, icon: Icon, color }) => (
    <Grid item xs={12} sm={6} md={3}>
      <Paper
        sx={{
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: '100%',
          backgroundColor: 'background.paper',
          borderLeft: 3,
          borderColor: `${color}.main`,
          '&:hover': {
            backgroundColor: `${color}.50`,
            '& .MuiSvgIcon-root': {
              color: `${color}.main`
            }
          },
          transition: 'all 0.3s ease'
        }}
      >
        <Icon sx={{ fontSize: 40, mb: 1, color: `${color}.main` }} />
        <Typography variant="h4" component="div" sx={{ mb: 1, color: 'text.primary' }}>
          {value}
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          {title}
        </Typography>
      </Paper>
    </Grid>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Typography variant="h4" gutterBottom sx={{ mb: 4 }}>
        Dashboard Overview
      </Typography>

      <Grid container spacing={3}>
        <StatCard
          title="Total Interviews"
          value={stats?.totalInterviews || 0}
          icon={InterviewIcon}
          color="primary"
        />
        <StatCard
          title="Active Jobs"
          value={stats?.totalJobs || 0}
          icon={WorkIcon}
          color="primary"
        />
        <StatCard
          title="Clients"
          value={stats?.totalClients || 0}
          icon={BusinessIcon}
          color="primary"
        />
        <StatCard
          title="Applicants"
          value={stats?.totalApplicants || 0}
          icon={PeopleIcon}
          color="primary"
        />
      </Grid>
    </Box>
  );
}

export default Dashboard;