import React, { useState, useEffect, useContext } from 'react';
import api from '../api';
import {
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  Pagination,
} from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import AuthContext from '../contexts/AuthContext';

function Users() {
  const { user } = useContext(AuthContext);
  const [users, setUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentUser, setCurrentUser] = useState({
    id: null,
    email: '',
    role: 'USER',
    password: '',
  });
  const [sortField, setSortField] = useState('email');
  const [sortOrder, setSortOrder] = useState('asc');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  useEffect(() => {
    fetchUsers();
  }, [page, itemsPerPage, sortField, sortOrder]);

  const fetchUsers = async () => {
    try {
      const response = await api.get('/users', {
        params: {
          page,
          itemsPerPage,
          sortField,
          sortOrder,
        },
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setUsers(response.data.users);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error('Error fetching users:', error);
      alert('Failed to fetch users.');
    }
  };

  const handleOpen = () => {
    setIsEditing(false);
    setCurrentUser({ id: null, email: '', role: 'USER', password: '' });
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleInputChange = (e) => {
    setCurrentUser({ ...currentUser, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    try {
      const dataToSend = { ...currentUser };
      if (isEditing && !dataToSend.password) {
        // Remove password field if it's empty when editing.
        delete dataToSend.password;
      }
      if (isEditing) {
        await api.put(`/users/${currentUser.id}`, dataToSend, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
      } else {
        await api.post('/users', dataToSend, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
      }
      fetchUsers();
      handleClose();
    } catch (error) {
      alert('Error saving user');
      console.error('Error:', error);
    }
  };

  const handleEdit = (user) => {
    setIsEditing(true);
    setCurrentUser({ ...user, password: '' }); // Initialize password as empty
    setOpen(true);
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      try {
        await api.delete(`/users/${id}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        fetchUsers();
      } catch (error) {
        alert('Error deleting user');
        console.error('Error:', error);
      }
    }
  };

  const handleSort = (field) => {
    setSortOrder(currentOrder => {
      if (sortField === field) {
        return currentOrder === 'asc' ? 'desc' : 'asc';
      }
      return 'asc';
    });
    setSortField(field);
  };

  // Restrict access to ADMIN users only
  if (user.role !== 'ADMIN') {
    return (
      <Typography variant="h6" color="error">
        Access denied. Admins only.
      </Typography>
    );
  }

  return (
    <>
      <Typography variant="h4" gutterBottom>
        Users
      </Typography>
      <Button variant="contained" color="primary" onClick={handleOpen}>
        Add New User
      </Button>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell
                onClick={() => handleSort('email')}
                style={{ cursor: 'pointer' }}
              >
                Email {sortField === 'email' && (sortOrder === 'asc' ? '↑' : '↓')}
              </TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((usr) => (
              <TableRow key={usr.id}>
                <TableCell>{usr.id}</TableCell>
                <TableCell>{usr.email}</TableCell>
                <TableCell>{usr.role}</TableCell>
                <TableCell>
                  <IconButton color="primary" onClick={() => handleEdit(usr)}>
                    <Edit />
                  </IconButton>
                  <IconButton color="error" onClick={() => handleDelete(usr.id)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{
        marginTop: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '20px'
      }}>
        <Pagination
          count={totalPages}
          page={page}
          onChange={(event, value) => setPage(value)}
          color="primary"
        />
      </div>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>{isEditing ? 'Edit User' : 'Add New User'}</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            name="email"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
            value={currentUser.email}
            onChange={handleInputChange}
            required
          />
          <TextField
            margin="dense"
            name="role"
            label="Role"
            type="text"
            fullWidth
            variant="standard"
            value={currentUser.role}
            onChange={handleInputChange}
            required
            select
            SelectProps={{
              native: true,
            }}
          >
            <option value="USER">USER</option>
            <option value="ADMIN">ADMIN</option>
          </TextField>
          <TextField
            margin="dense"
            name="password"
            label="Password"
            type="password"
            fullWidth
            variant="standard"
            value={currentUser.password}
            onChange={handleInputChange}
            required={!isEditing}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>{isEditing ? 'Update' : 'Add'}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Users;