import React from 'react';
import { Button } from '@mui/material';
import { PlayArrow as PlayIcon } from '@mui/icons-material';

const VideoButton = ({ url }) => {
    if (!url) return null;

    return (
        <Button
            variant="outlined"
            startIcon={<PlayIcon />}
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            sx={{
                minWidth: '120px',
                color: 'primary.main',
                borderColor: 'primary.main',
                '&:hover': {
                    backgroundColor: 'primary.50',
                    borderColor: 'primary.main',
                }
            }}
        >
            View Video
        </Button>
    );
};

export default VideoButton; 