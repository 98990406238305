import React, { useState, useEffect } from 'react';
import api from '../api'; // Import the api instance
import {
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  Pagination,
} from '@mui/material';
import { Edit, Delete, FileDownload } from '@mui/icons-material';

function Applicants() {
  const [applicants, setApplicants] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentApplicant, setCurrentApplicant] = useState({
    id: null,
    firstName: '',
    lastName: '',
    email: '',
  });
  const [sortField, setSortField] = useState('firstName');
  const [sortOrder, setSortOrder] = useState('asc');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  useEffect(() => {
    fetchApplicants();
  }, [page, itemsPerPage, sortField, sortOrder]);

  const fetchApplicants = async () => {
    try {
      const response = await api.get('/applicants', {
        params: {
          page,
          itemsPerPage,
          sortField,
          sortOrder,
        }
      });
      setApplicants(response.data.applicants);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error('Error fetching applicants:', error);
    }
  };

  const handleClose = () => setOpen(false);

  const handleInputChange = (e) => {
    setCurrentApplicant({ ...currentApplicant, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    try {
      await api.put(`/applicants/${currentApplicant.id}`, currentApplicant);
      fetchApplicants();
      handleClose();
    } catch (error) {
      alert('Error updating applicant');
      console.error('Error:', error);
    }
  };

  const handleEdit = (applicant) => {
    setCurrentApplicant({ ...applicant });
    setOpen(true);
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this applicant?')) {
      try {
        await api.delete(`/applicants/${id}`);
        fetchApplicants();
      } catch (error) {
        alert('Error deleting applicant');
        console.error('Error:', error);
      }
    }
  };

  const handleSort = (field) => {
    setSortOrder(currentOrder => {
      if (sortField === field) {
        return currentOrder === 'asc' ? 'desc' : 'asc';
      }
      return 'asc';
    });
    setSortField(field);
  };

  const handleExport = async () => {
    try {
      const response = await api.get('/export/applicants', {
        responseType: 'blob'
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `applicants-${Date.now()}.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error exporting applicants:', error);
      alert('Error exporting applicants');
    }
  };

  return (
    <>
      <Typography variant="h4" gutterBottom>
        Applicants
      </Typography>
      <div style={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<FileDownload />}
          onClick={handleExport}
        >
          Export to CSV
        </Button>
      </div>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell
                onClick={() => handleSort('firstName')}
                style={{ cursor: 'pointer' }}
              >
                First Name {sortField === 'firstName' && (sortOrder === 'asc' ? '↑' : '↓')}
              </TableCell>
              <TableCell
                onClick={() => handleSort('lastName')}
                style={{ cursor: 'pointer' }}
              >
                Last Name {sortField === 'lastName' && (sortOrder === 'asc' ? '↑' : '↓')}
              </TableCell>
              <TableCell
                onClick={() => handleSort('email')}
                style={{ cursor: 'pointer' }}
              >
                Email {sortField === 'email' && (sortOrder === 'asc' ? '↑' : '↓')}
              </TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {applicants.map((applicant) => (
              <TableRow key={applicant.id}>
                <TableCell>{applicant.id}</TableCell>
                <TableCell>{applicant.firstName}</TableCell>
                <TableCell>{applicant.lastName}</TableCell>
                <TableCell>{applicant.email}</TableCell>
                <TableCell>
                  <IconButton color="primary" onClick={() => handleEdit(applicant)}>
                    <Edit />
                  </IconButton>
                  <IconButton color="error" onClick={() => handleDelete(applicant.id)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{
        marginTop: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '20px'
      }}>
        <Pagination
          count={totalPages}
          page={page}
          onChange={(event, value) => setPage(value)}
          color="primary"
        />
      </div>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>Edit Applicant</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            name="firstName"
            label="First Name"
            type="text"
            fullWidth
            variant="standard"
            value={currentApplicant.firstName}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="lastName"
            label="Last Name"
            type="text"
            fullWidth
            variant="standard"
            value={currentApplicant.lastName}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="email"
            label="Email"
            type="email"
            fullWidth
            variant="standard"
            value={currentApplicant.email}
            onChange={handleInputChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Update</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Applicants;
