import React, { useState, useEffect } from 'react';
import api from '../api';
import {
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  Pagination,
} from '@mui/material';
import { Edit, Delete, FileDownload } from '@mui/icons-material';

function Clients() {
  const [clients, setClients] = useState([]);
  const [open, setOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentClient, setCurrentClient] = useState({
    id: null,
    companyName: '',
    contactEmail: '',
  });
  const [sortField, setSortField] = useState('companyName');
  const [sortOrder, setSortOrder] = useState('asc');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  useEffect(() => {
    fetchClients();
  }, [page, itemsPerPage, sortField, sortOrder]);

  const fetchClients = async () => {
    const response = await api.get('/clients', {
      params: {
        page,
        itemsPerPage,
        sortField,
        sortOrder,
      }
    });
    setClients(response.data.clients);
    setTotalPages(response.data.totalPages);
  };

  const handleOpen = () => {
    setIsEditing(false);
    setCurrentClient({ id: null, companyName: '', contactEmail: '' });
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleInputChange = (e) => {
    setCurrentClient({ ...currentClient, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    try {
      if (isEditing) {
        await api.put(`/clients/${currentClient.id}`, currentClient);
      } else {
        await api.post('/clients', currentClient);
      }
      fetchClients();
      handleClose();
    } catch (error) {
      alert('Error saving client');
    }
  };

  const handleEdit = (client) => {
    setIsEditing(true);
    setCurrentClient(client);
    setOpen(true);
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this client?')) {
      try {
        await api.delete(`/clients/${id}`);
        fetchClients();
      } catch (error) {
        alert('Error deleting client');
      }
    }
  };

  const handleSort = (field) => {
    setSortOrder(currentOrder => {
      if (sortField === field) {
        return currentOrder === 'asc' ? 'desc' : 'asc';
      }
      return 'asc';
    });
    setSortField(field);
  };

  const handleExport = async () => {
    try {
      const response = await api.get('/export/clients', {
        responseType: 'blob'
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `clients-${Date.now()}.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error exporting clients:', error);
      alert('Error exporting clients');
    }
  };

  return (
    <>
      <Typography variant="h4" gutterBottom>
        Clients
      </Typography>
      <div style={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
        <Button variant="contained" color="primary" onClick={handleOpen}>
          Add New Client
        </Button>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<FileDownload />}
          onClick={handleExport}
        >
          Export to CSV
        </Button>
      </div>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell
                onClick={() => handleSort('companyName')}
                style={{ cursor: 'pointer' }}
              >
                Company Name {sortField === 'companyName' && (sortOrder === 'asc' ? '↑' : '↓')}
              </TableCell>
              <TableCell
                onClick={() => handleSort('contactEmail')}
                style={{ cursor: 'pointer' }}
              >
                Contact Email {sortField === 'contactEmail' && (sortOrder === 'asc' ? '↑' : '↓')}
              </TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clients.map((client) => (
              <TableRow key={client.id}>
                <TableCell>{client.id}</TableCell>
                <TableCell>{client.companyName}</TableCell>
                <TableCell>{client.contactEmail}</TableCell>
                <TableCell>
                  <IconButton
                    color="primary"
                    onClick={() => handleEdit(client)}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    color="error"
                    onClick={() => handleDelete(client.id)}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{
        marginTop: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '20px'
      }}>
        <Pagination
          count={totalPages}
          page={page}
          onChange={(event, value) => setPage(value)}
          color="primary"
        />
      </div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {isEditing ? 'Edit Client' : 'Add New Client'}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="companyName"
            label="Company Name"
            type="text"
            fullWidth
            variant="standard"
            value={currentClient.companyName}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="contactEmail"
            label="Contact Email"
            type="email"
            fullWidth
            variant="standard"
            value={currentClient.contactEmail}
            onChange={handleInputChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>
            {isEditing ? 'Update' : 'Add'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Clients;