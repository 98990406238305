import React, { useState, useEffect } from 'react';
import api from '../api';
import {
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  Tooltip,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Pagination,
  Checkbox,
  Divider,
} from '@mui/material';
import { Edit, Delete, Visibility, Email, Replay, FileDownload } from '@mui/icons-material';
import VideoButton from './VideoButton';

// Helper function to format duration
const formatDuration = (durationMs) => {
  if (!durationMs) return 'N/A';
  const durationInSeconds = Math.floor(durationMs / 1000);
  const minutes = Math.floor(durationInSeconds / 60);
  const seconds = durationInSeconds % 60;
  return `${minutes}m ${seconds}s`;
};

function Interviews() {
  const [interviews, setInterviews] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentInterview, setCurrentInterview] = useState({
    id: null,
    videoURL: '',
    callId: '',
    applicantId: '',
    jobId: '',
    scorecard: {},
    channelARN: '',
    livekitRoom: '',
  });
  const [viewOpen, setViewOpen] = useState(false);
  const [viewInterview, setViewInterview] = useState(null);
  const [viewInterviewDetails, setViewInterviewDetails] = useState(null);
  const [jobs, setJobs] = useState([]);
  const [clients, setClients] = useState([]);
  const [selectedJob, setSelectedJob] = useState('');
  const [selectedClient, setSelectedClient] = useState('');
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [selectedInterviews, setSelectedInterviews] = useState([]);
  const [sortField, setSortField] = useState('createdAt');
  const [sortOrder, setSortOrder] = useState('desc');
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchInterviews();
    fetchJobs();
    fetchClients();
  }, [page, selectedJob, selectedClient, itemsPerPage, sortField, sortOrder]);

  useEffect(() => {
    if (selectedClient !== '') {
      const filtered = jobs.filter(
        (job) => job.client && job.client.id === parseInt(selectedClient)
      );
      setFilteredJobs(filtered);
    } else {
      setFilteredJobs(jobs);
    }
  }, [selectedClient, jobs]);

  useEffect(() => {
    if (selectedJob !== '') {
      const selectedJobObj = jobs.find(
        (job) => job.id === parseInt(selectedJob)
      );
      if (selectedJobObj && selectedJobObj.client) {
        setFilteredClients([selectedJobObj.client]);
      } else {
        setFilteredClients(clients);
      }
    } else {
      setFilteredClients(clients);
    }
  }, [selectedJob, jobs, clients]);

  useEffect(() => {
    if (selectedClient === '' && selectedJob === '') {
      setFilteredJobs(jobs);
      setFilteredClients(clients);
    }
  }, [interviews, selectedClient, selectedJob, jobs, clients]);

  const fetchInterviews = async () => {
    try {
      setLoading(true); // Set loading state to true when fetching starts
      const response = await api.get('/interviews', {
        params: {
          include: 'applicant,job.client',
          page,
          itemsPerPage,
          jobId: selectedJob || undefined,
          clientId: selectedClient || undefined,
          sortField,
          sortOrder,
          searchTerm: searchTerm || undefined,
        },
      });
      setInterviews(response.data.interviews);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error('Error fetching interviews:', error);
    } finally {
      setLoading(false); // Set loading state to false when fetching ends
    }
  };

  const fetchJobs = async () => {
    try {
      const response = await api.get('/jobs', {
        params: {
          itemsPerPage: 1000
        }
      });
      setJobs(response.data.jobs);
    } catch (error) {
      console.error('Error fetching jobs:', error);
    }
  };

  const fetchClients = async () => {
    try {
      const response = await api.get('/clients', {
        params: {
          itemsPerPage: 1000
        }
      });
      setClients(response.data.clients);
    } catch (error) {
      console.error('Error fetching clients:', error);
    }
  };

  const handleClose = () => setOpen(false);

  const handleViewOpen = (interview) => {
    setViewInterview(interview);
    if (interview.applicant && interview.job) {
      setViewInterviewDetails({
        ...interview,
        applicant: interview.applicant,
        job: interview.job,
        client: interview.job.client
      });
      setViewOpen(true);
    } else {
      console.error('Interview is missing required data:', interview);
    }
  };

  const handleViewClose = () => setViewOpen(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let parsedValue = value;

    if (name === 'applicantId' || name === 'jobId') {
      parsedValue = parseInt(value, 10) || '';
    }

    setCurrentInterview({ ...currentInterview, [name]: parsedValue });
  };

  const handleSubmit = async () => {
    try {
      const interviewData = {
        videoURL: currentInterview.videoURL,
        callId: currentInterview.callId,
        channelARN: currentInterview.channelARN,
        applicantId: parseInt(currentInterview.applicantId, 10),
        jobId: parseInt(currentInterview.jobId, 10)
      };

      if (!interviewData.applicantId || !interviewData.jobId) {
        alert('Applicant ID and Job ID are required');
        return;
      }

      await api.put(`/interviews/${currentInterview.id}`, interviewData);
      fetchInterviews();
      handleClose();
    } catch (error) {
      console.error('Error updating interview:', error);
      alert('Error updating interview. Please try again.');
    }
  };

  const renderScorecard = (scorecard) => {
    if (!scorecard || !scorecard.attributes) return null;

    return Object.entries(scorecard.attributes).map(([category, data]) => (
      <div key={category}>
        <Typography variant="h6">{category}</Typography>
        <Typography>Score: {data.score}</Typography>
        <Typography>Feedback: {data.client_focused_feedback}</Typography>
      </div>
    ));
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this interview?')) {
      try {
        await api.delete(`/interviews/${id}`);
        fetchInterviews(); // Refresh the list after deletion
      } catch (error) {
        console.error('Error deleting interview:', error);
      }
    }
  };

  const handleSendEmail = async (interviewId) => {
    try {
      const response = await api.post(`/interviews/${interviewId}/send-email`);
      if (response.status === 200) {
        // Show success message
        alert('Email sent successfully');
      }
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Error sending email. Please try again.');
    }
  };

  // Add this new function after handleSendEmail
  const handleRetryVideo = async (interviewId) => {
    try {
      const baseUrl = process.env.REACT_APP_INTERVIEW_BASE_URL;
      console.log('baseUrl', baseUrl);
      const response = await fetch(`${baseUrl}/retry-interview-video/${interviewId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        fetchInterviews();
      } else {
        throw new Error(`Failed to retry video: ${response.statusText}`);
      }
    } catch (error) {
      console.error('Error retrying video:', error);
      alert('Error retrying video. Please try again.');
    }
  };

  const filteredInterviews = interviews.filter(interview => {
    if (selectedJob && interview.jobId !== parseInt(selectedJob)) return false;
    if (selectedClient && interview.job.client.id !== parseInt(selectedClient)) return false;
    return true;
  });

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  // Add this new function to handle bulk deletion
  const handleBulkDelete = async () => {
    if (window.confirm(`Are you sure you want to delete ${selectedInterviews.length} interviews?`)) {
      try {
        await api.post('/interviews/bulk-delete', { ids: selectedInterviews });
        setSelectedInterviews([]); // Clear selection
        fetchInterviews(); // Refresh the list
      } catch (error) {
        console.error('Error bulk deleting interviews:', error);
        alert('Error deleting interviews. Please try again.');
      }
    }
  };

  // Add this function to handle checkbox selection
  const handleSelectInterview = (id) => {
    setSelectedInterviews(prev => {
      if (prev.includes(id)) {
        return prev.filter(itemId => itemId !== id);
      } else {
        return [...prev, id];
      }
    });
  };

  // Add this function to handle "select all" checkbox
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedInterviews(interviews.map(interview => interview.id));
    } else {
      setSelectedInterviews([]);
    }
  };

  // Add this function to handle rows per page changes
  const handleChangeRowsPerPage = (event) => {
    setItemsPerPage(parseInt(event.target.value));
    setPage(1); // Reset to first page when changing page size
  };

  const handleSort = (field) => {
    setSortOrder(currentOrder => {
      if (sortField === field) {
        return currentOrder === 'asc' ? 'desc' : 'asc';
      }
      return 'asc';
    });
    setSortField(field);
  };

  const handleExport = async () => {
    try {
      const response = await api.get('/export/interviews', {
        responseType: 'blob'
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `interviews-${Date.now()}.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error exporting interviews:', error);
      alert('Error exporting interviews');
    }
  };

  const handleClientChange = (e) => {
    setSelectedClient(e.target.value);
    setPage(1); // Reset to first page
  };

  const handleJobChange = (e) => {
    setSelectedJob(e.target.value);
    setPage(1); // Reset to first page
  };

  // New function to handle search
  const handleSearch = () => {
    setPage(1); // Reset to first page when initiating a new search
    fetchInterviews();
  };

  // Update the clear search function to reset to empty state
  const handleClearSearch = () => {
    setSearchTerm('');
    setPage(1);
    // Call fetchInterviews without the search term
    api.get('/interviews', {
      params: {
        include: 'applicant,job.client',
        page: 1,
        itemsPerPage,
        jobId: selectedJob || undefined,
        clientId: selectedClient || undefined,
        sortField,
        sortOrder,
      },
    }).then(response => {
      setInterviews(response.data.interviews);
      setTotalPages(response.data.totalPages);
    }).catch(error => {
      console.error('Error fetching interviews:', error);
    });
  };

  return (
    <div style={{ padding: '16px', maxWidth: '100%', overflow: 'hidden' }}>
      <Typography variant="h4" gutterBottom style={{ fontSize: '1.5rem' }}>
        Interviews
      </Typography>

      <div style={{
        display: 'flex',
        gap: '12px',
        marginBottom: '16px',
        alignItems: 'center',
        flexWrap: 'wrap',
        maxWidth: '100%'
      }}>
        {selectedInterviews.length > 0 && (
          <Button
            variant="contained"
            color="error"
            onClick={handleBulkDelete}
          >
            Delete Selected ({selectedInterviews.length})
          </Button>
        )}
        <Button
          variant="outlined"
          color="primary"
          startIcon={<FileDownload />}
          onClick={handleExport}
        >
          Export to CSV
        </Button>
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <Typography>Rows per page:</Typography>
          <Select
            value={itemsPerPage}
            onChange={handleChangeRowsPerPage}
            size="small"
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px', flexGrow: 1 }}>
          <TextField
            label="Search"
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            fullWidth
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSearch}
          >
            Search
          </Button>
          {searchTerm && (
            <Button
              variant="outlined"
              onClick={handleClearSearch}
            >
              Clear
            </Button>
          )}
        </div>
      </div>
      <div style={{
        display: 'flex',
        gap: '12px',
        marginBottom: '16px',
        flexWrap: 'wrap'
      }}>
        <FormControl style={{ minWidth: 200 }}>
          <Select
            value={selectedClient}
            onChange={handleClientChange}
            displayEmpty
            renderValue={(selected) => {
              if (selected === '') {
                return 'Filter by Client';
              }
              const client = clients.find(
                (client) => client.id === parseInt(selected)
              );
              return client ? client.companyName : '';
            }}
          >
            <MenuItem value="">All Clients</MenuItem>
            {filteredClients.map((client) => (
              <MenuItem key={client.id} value={client.id}>
                {client.companyName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl style={{ minWidth: 200 }}>
          <Select
            value={selectedJob}
            onChange={handleJobChange}
            displayEmpty
            renderValue={(selected) => {
              if (selected === '') {
                return 'Filter by Job';
              }
              const job = jobs.find((job) => job.id === parseInt(selected));
              return job ? job.title : '';
            }}
          >
            <MenuItem value="">All Jobs</MenuItem>
            {filteredJobs.map((job) => (
              <MenuItem key={job.id} value={job.id}>
                {job.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      {loading ? (
        <Typography>Loading...</Typography>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={interviews.length > 0 && selectedInterviews.length === interviews.length}
                      indeterminate={selectedInterviews.length > 0 && selectedInterviews.length < interviews.length}
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <TableCell>ID</TableCell>
                  <TableCell
                    onClick={() => handleSort('applicant')}
                    style={{ cursor: 'pointer' }}
                  >
                    Applicant {sortField === 'applicant' && (sortOrder === 'asc' ? '↑' : '↓')}
                  </TableCell>
                  <TableCell
                    onClick={() => handleSort('email')}
                    style={{ cursor: 'pointer' }}
                  >
                    Email {sortField === 'email' && (sortOrder === 'asc' ? '↑' : '↓')}
                  </TableCell>
                  <TableCell
                    onClick={() => handleSort('jobTitle')}
                    style={{ cursor: 'pointer' }}
                  >
                    Job Title {sortField === 'jobTitle' && (sortOrder === 'asc' ? '↑' : '↓')}
                  </TableCell>
                  <TableCell
                    onClick={() => handleSort('jobRole')}
                    style={{ cursor: 'pointer' }}
                  >
                    Job Role {sortField === 'jobRole' && (sortOrder === 'asc' ? '↑' : '↓')}
                  </TableCell>
                  <TableCell
                    onClick={() => handleSort('companyName')}
                    style={{ cursor: 'pointer' }}
                  >
                    Company Name {sortField === 'companyName' && (sortOrder === 'asc' ? '↑' : '↓')}
                  </TableCell>
                  <TableCell>Video</TableCell>
                  <TableCell
                    onClick={() => handleSort('duration')}
                    style={{ cursor: 'pointer' }}
                  >
                    Duration {sortField === 'duration' && (sortOrder === 'asc' ? '↑' : '↓')}
                  </TableCell>
                  <TableCell
                    onClick={() => handleSort('overallScore')}
                    style={{ cursor: 'pointer' }}
                  >
                    Overall Score {sortField === 'overallScore' && (sortOrder === 'asc' ? '↑' : '↓')}
                  </TableCell>
                  <TableCell
                    onClick={() => handleSort('createdAt')}
                    style={{ cursor: 'pointer' }}
                  >
                    Created At {sortField === 'createdAt' && (sortOrder === 'asc' ? '↑' : '↓')}
                  </TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {interviews.map((interview) => (
                  <TableRow
                    key={interview.id}
                    selected={selectedInterviews.includes(interview.id)}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedInterviews.includes(interview.id)}
                        onChange={() => handleSelectInterview(interview.id)}
                      />
                    </TableCell>
                    <TableCell>{interview.id}</TableCell>
                    <TableCell>{`${interview.applicant.firstName} ${interview.applicant.lastName}`}</TableCell>
                    <TableCell>{interview.applicant.email}</TableCell>
                    <TableCell>{interview.job.title}</TableCell>
                    <TableCell>{interview.job.role}</TableCell>
                    <TableCell>{interview.job.client.companyName}</TableCell>
                    <TableCell>
                      {interview.videoURL ? (
                        <VideoButton url={`/video/${interview.callId}`} />
                      ) : (
                        'No video'
                      )}
                    </TableCell>
                    <TableCell>{formatDuration(interview.duration)}</TableCell>
                    <TableCell>{interview.overallScore || 'N/A'}</TableCell>
                    <TableCell>{new Date(interview.createdAt).toLocaleString()}</TableCell>
                    <TableCell>
                      <Tooltip title="View">
                        <IconButton onClick={() => handleViewOpen(interview)}>
                          <Visibility />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Edit">
                        <IconButton color="primary" onClick={() => {
                          setCurrentInterview(interview);
                          setOpen(true);
                        }}>
                          <Edit />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <IconButton color="error" onClick={() => handleDelete(interview.id)}>
                          <Delete />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Send Email">
                        <IconButton color="secondary" onClick={() => handleSendEmail(interview.id)}>
                          <Email />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Retry Video">
                        <IconButton
                          color="primary"
                          onClick={() => handleRetryVideo(interview.id)}
                        >
                          <Replay />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div style={{
            marginTop: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '20px'
          }}>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
              color="primary"
            />
          </div>
        </>
      )}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit Interview</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            name="videoURL"
            label="Video URL"
            type="text"
            fullWidth
            value={currentInterview.videoURL}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="callId"
            label="Call ID"
            type="text"
            fullWidth
            value={currentInterview.callId}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="channelARN"
            label="Channel ARN"
            type="text"
            fullWidth
            value={currentInterview.channelARN || ''}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="livekitRoom"
            label="LiveKit Room"
            type="text"
            fullWidth
            value={currentInterview.livekitRoom || ''}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="applicantId"
            label="Applicant ID"
            type="number"
            fullWidth
            value={currentInterview.applicantId}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="jobId"
            label="Job ID"
            type="number"
            fullWidth
            value={currentInterview.jobId}
            onChange={handleInputChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Update</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={viewOpen} onClose={handleViewClose} maxWidth="md" fullWidth>
        <DialogTitle>Interview Details</DialogTitle>
        <DialogContent>
          {viewInterviewDetails && (
            <>
              <Typography variant="h6">General Information</Typography>
              <Typography>ID: {viewInterviewDetails.id}</Typography>
              <Typography>Video URL: {viewInterviewDetails.videoURL}</Typography>
              <Typography>Call ID: {viewInterviewDetails.callId}</Typography>
              <Typography>Applicant: {`${viewInterviewDetails.applicant.firstName} ${viewInterviewDetails.applicant.lastName}`}</Typography>
              <Typography>Job Title: {viewInterviewDetails.job.title}</Typography>
              <Typography>Role: {viewInterviewDetails.job.role}</Typography>
              <Typography>Company Name: {viewInterviewDetails.job.client.companyName}</Typography>
              <Typography>Created At: {new Date(viewInterviewDetails.createdAt).toLocaleString()}</Typography>

              <Typography variant="h6" style={{ marginTop: '20px' }}>Scorecard</Typography>
              {renderScorecard(viewInterviewDetails.scorecard)}
              {viewInterviewDetails.scorecard && (
                <>
                  <Typography variant="h6" style={{ marginTop: '20px' }}>Overall Score</Typography>
                  <Typography>Score: {viewInterviewDetails.scorecard.overall_score}</Typography>
                  <Typography>Overall Feedback: {viewInterviewDetails.scorecard.client_focused_overall_feedback}</Typography>
                </>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleViewClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Interviews;
