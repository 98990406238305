import { Box, CircularProgress, Link, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { useParams } from "react-router-dom";

function VideoPlayer() {
  const { callId } = useParams();
  const [playbackRate, setPlaybackRate] = useState(1);
  const [interviewData, setInterviewData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  const payload = React.useMemo(
    () => interviewData?.payload && JSON.parse(interviewData.payload),
    [interviewData]
  );

  useEffect(() => {
    const fetchInterviewData = async () => {
      try {
        const response = await axios.get(`/api/video/${callId}`);
        setInterviewData(response.data);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch interview data");
        setLoading(false);
      }
    };

    fetchInterviewData();
  }, [callId]);

  const handlePlaybackRateChange = (rate) => {
    setPlaybackRate(rate);
  };

  const renderScorecard = (scorecard) => {
    if (!scorecard || !scorecard.attributes) return null;

    return (
      <>
        {Object.entries(scorecard.attributes).map(([category, data]) => (
          <Box key={category} sx={{ mb: 2 }}>
            <Typography variant="subtitle1">{category}</Typography>
            <Typography variant="body2">Score: {data.score}</Typography>
            <Typography variant="body2">
              {data.client_focused_feedback}
            </Typography>
          </Box>
        ))}

        <Box sx={{ mt: 3 }}>
          <Typography variant="subtitle1">
            Overall Score: {scorecard.overall_score}
          </Typography>
          <Typography variant="body2">
            {scorecard.client_focused_overall_feedback}
          </Typography>
        </Box>
      </>
    );
  };

  const playbackRates = [0.5, 1, 1.25, 1.5, 2];

  const PlaybackSpeedControls = () => (
    <Box sx={{ display: 'flex', gap: 1, mt: 2 }}>
      {playbackRates.map((rate) => (
        <button
          key={rate}
          onClick={() => handlePlaybackRateChange(rate)}
          style={{
            padding: '4px 8px',
            backgroundColor: playbackRate === rate ? '#5D1974' : '#ffffff',
            color: playbackRate === rate ? '#ffffff' : '#000000',
            border: '1px solid #5D1974',
            borderRadius: '4px',
            cursor: 'pointer',
          }}
        >
          {rate}x
        </button>
      ))}
    </Box>
  );

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  if (!interviewData) {
    return <Typography>No interview data found</Typography>;
  }

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: ["1fr", "1fr", "2fr 1fr"],
        height: "100vh",
        overflow: "hidden",
        backgroundColor: "#f5f5f5",
      }}
    >
      <Box
        p={4}
        display="flex"
        flexDirection="column"
        bgcolor="#FAF9F8"
        sx={{
          height: "100vh",
          overflow: "auto",
          gap: 2,
          '& > *': {
            width: '100%',
            maxWidth: '100%',
          },
          color: '#000000',
        }}
      >
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="h4" fontSize="1rem" fontWeight={400} sx={{ color: '#000000' }}>
            {interviewData.job.title} &ndash; {interviewData.job.role}
          </Typography>

          <Typography variant="h1" fontWeight={500} fontSize="1.625rem" sx={{ color: '#000000' }}>
            {[
              interviewData.applicant.firstName,
              interviewData.applicant.lastName,
            ].join(" ")}
          </Typography>

          <Link
            href={`${process.env.REACT_APP_API_URL}/interview/${interviewData?.callId}.pdf`}
            target="_blank"
            sx={{
              display: "inline-flex",
              width: "fit-content",
              alignItems: "center",
              gap: 1,
              color: "inherit",
              textDecoration: "none",
              fontSize: "0.8125rem",
              fontWeight: 500,
              "&:hover": {
                textDecoration: "underline",
              },
            }}
          >
            <svg
              width="1.2em"
              viewBox="0 0 23 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.14233 3.09984C7.50147 2.18921 8.79024 2.18921 9.14938 3.09984L10.2623 5.9218C10.372 6.19982 10.5921 6.41989 10.8701 6.52954L13.692 7.64251C14.6027 8.00166 14.6027 9.29042 13.692 9.64957L10.8701 10.7625C10.5921 10.8722 10.372 11.0923 10.2623 11.3703L9.14938 14.1922C8.79023 15.1029 7.50147 15.1029 7.14232 14.1922L6.02936 11.3703C5.91971 11.0923 5.69964 10.8722 5.42162 10.7625L2.59966 9.64956C1.68903 9.29042 1.68903 8.00165 2.59966 7.64251L5.42162 6.52954C5.69964 6.41989 5.91971 6.19982 6.02936 5.9218L7.14233 3.09984Z"
                fill="#5D1974"
              />
              <path
                d="M16.2194 13.3338C16.4167 12.8334 17.125 12.8334 17.3223 13.3338L18.1855 15.5224C18.2457 15.6752 18.3667 15.7961 18.5195 15.8564L20.708 16.7195C21.2085 16.9169 21.2085 17.6251 20.708 17.8225L18.5195 18.6856C18.3667 18.7459 18.2457 18.8668 18.1855 19.0196L17.3223 21.2082C17.125 21.7086 16.4167 21.7086 16.2194 21.2082L15.3562 19.0196C15.2959 18.8668 15.175 18.7459 15.0222 18.6856L12.8337 17.8225C12.3332 17.6251 12.3332 16.9169 12.8337 16.7195L15.0222 15.8564C15.175 15.7961 15.2959 15.6752 15.3562 15.5224L16.2194 13.3338Z"
                fill="#5D1974"
              />
            </svg>
            View scorecard
          </Link>
        </Box>

        <Box
          sx={{
            position: "relative",
            aspectRatio: "16/9",
            width: "100%",
            backgroundColor: '#000000',
            borderRadius: 3,
            overflow: 'hidden',
          }}
        >
          <ReactPlayer
            url={interviewData.videoURL}
            controls
            width="100%"
            height="100%"
            playbackRate={playbackRate}
            style={{ position: 'absolute', top: 0, left: 0 }}
            onProgress={({ playedSeconds }) => setCurrentTime(playedSeconds)}
            onDuration={(d) => setDuration(d)}
          />
        </Box>

        <PlaybackSpeedControls />
      </Box>

      <Box
        p={4}
        sx={{
          height: "100vh",
          overflow: "auto",
          borderLeft: "1px solid #E9E7E4",
          backgroundColor: "#ffffff",
          color: '#000000',
        }}
      >
        <Typography
          variant="h5"
          fontSize="1.25rem"
          fontWeight={500}
          sx={{ color: '#000000' }}
          mb={2}
        >
          Transcript
        </Typography>

        {payload?.call?.transcript_object?.map((i) => (
          <Box key={i.content} py={1}>
            <Typography
              fontWeight={500}
              fontSize="0.8125rem"
              sx={{ color: '#000000' }}
              mb={0.5}
            >
              {i.role === "agent" && "AIR"}
              {i.role === "user" &&
                [
                  interviewData.applicant.firstName,
                  interviewData.applicant.lastName,
                ].join(" ")}
            </Typography>

            <Typography
              fontSize="0.8125rem"
              sx={{ color: '#000000' }}
              dangerouslySetInnerHTML={{
                __html: i.content.replace("\n", "<br />"),
              }}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default VideoPlayer;
