import React, { useState, useEffect } from 'react';
import api from '../api';
import {
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';

function DefaultAttributes() {
  const [defaultAttributes, setDefaultAttributes] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentAttribute, setCurrentAttribute] = useState({
    id: null,
    attributeKey: '',
    description: '',
    scoringGuide: {},
  });
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    fetchDefaultAttributes();
  }, []);

  const fetchDefaultAttributes = async () => {
    const response = await api.get('/default-attributes');
    setDefaultAttributes(response.data);
  };

  const handleOpen = () => {
    setIsEditing(false);
    setCurrentAttribute({
      id: null,
      attributeKey: '',
      description: '',
      scoringGuide: {},
    });
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleInputChange = (e) => {
    setCurrentAttribute({ ...currentAttribute, [e.target.name]: e.target.value });
  };

  const handleScoringGuideChange = (score, value) => {
    setCurrentAttribute({
      ...currentAttribute,
      scoringGuide: { ...currentAttribute.scoringGuide, [score]: value },
    });
  };

  const handleSubmit = async () => {
    try {
      if (isEditing) {
        await api.put(`/default-attributes/${currentAttribute.id}`, currentAttribute);
      } else {
        await api.post('/default-attributes', currentAttribute);
      }
      fetchDefaultAttributes();
      handleClose();
    } catch (error) {
      console.error('Error saving default attribute:', error);
    }
  };

  const handleEdit = (attribute) => {
    setIsEditing(true);
    setCurrentAttribute(attribute);
    setOpen(true);
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this default attribute?')) {
      try {
        await api.delete(`/default-attributes/${id}`);
        fetchDefaultAttributes();
      } catch (error) {
        console.error('Error deleting default attribute:', error);
      }
    }
  };

  return (
    <>
      <Typography variant="h4" gutterBottom>
        Default Attributes
      </Typography>
      <Button variant="contained" color="primary" onClick={handleOpen}>
        Add New Default Attribute
      </Button>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Attribute Key</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {defaultAttributes.map((attribute) => (
              <TableRow key={attribute.id}>
                <TableCell>{attribute.attributeKey}</TableCell>
                <TableCell>{attribute.description}</TableCell>
                <TableCell>
                  <Button startIcon={<Edit />} onClick={() => handleEdit(attribute)}>
                    Edit
                  </Button>
                  <Button startIcon={<Delete />} onClick={() => handleDelete(attribute.id)}>
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{isEditing ? 'Edit Default Attribute' : 'Add New Default Attribute'}</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            name="attributeKey"
            label="Attribute Key"
            fullWidth
            value={currentAttribute.attributeKey}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="description"
            label="Description"
            fullWidth
            value={currentAttribute.description}
            onChange={handleInputChange}
          />
          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            Scoring Guide
          </Typography>
          {[1, 2, 3, 4, 5].map((score) => (
            <TextField
              key={score}
              margin="dense"
              label={`Score ${score}`}
              fullWidth
              multiline
              rows={2}
              value={currentAttribute.scoringGuide[score] || ''}
              onChange={(e) => handleScoringGuideChange(score, e.target.value)}
            />
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>{isEditing ? 'Update' : 'Add'}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DefaultAttributes;
